import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";
import BanerMDD from "../../components/BanerMDD/BanerMDD";

const Vortune = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Vortune",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #df7a61, #eb876e, #FE8A6E, #eb876e, #df7a61 )",
        icons: "#FE8A6E",
        navClass: "vortune",
        ogImage: require("../../assets/img/portfolio/vortune_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/vortune/",
      }}
    >
      <PortfolioHeader name="vortune" height="81" />
      <section className="container-fluid vortune_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Vortune Equity</h1>
              <ul>
                <li>Strona internetowa</li>
                <li>Kampania leadowa</li>
                <li>Banery reklamowe</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                W ramach współpracy z naszym stałym Klientem otrzymaliśmy
                zlecenie stworzenia Landing Page'a, którego celem byłoby
                pozyskiwanie potencjalnych inwestorów zainteresowanych
                inwestycjami w nieruchomości. Strona miała za zadanie
                przedstawić ofertę inwestycyjną zakupu piętra pod wynajem lokali
                usługowych we wrocławskim biurowcu SQ Business Center.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid vortune_section_3">
        <div className="row row_1">
          <div className="col-md-6">
            <p>
              Przygotowaliśmy plan marketingowy z analizą rynku i prognozami na
              najbliższe miesiące. Stworzyliśmy także pełen pakiet banerów
              marketingowych na cele promocji strony w sieciach reklamowych
              online.
            </p>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/vortune_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters row_2">
          <div className="col-md-5">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/vortune_main_1.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters row_3">
          <div className="col-md-7 offset-md-5 text-right">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/vortune_main_2.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters align-items-center row_4">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/vortune_main_3.png")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <p>
              Na bazie zakodowanej przez nas strony www sukcesywnie prowadzimy
              dla Klienta kampanię marketingową w mediach społecznościowych oraz
              sieci reklamowej Google i Facebook Ads.
            </p>
          </div>
        </div>
      </section>

      <BanerMDD />
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Vortune;
